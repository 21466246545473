<template>
  <div class="base-form h-100 d-flex justify-content-between flex-column ">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-5 mx-auto">
        <div class="card card-form _error" style="width:500px">
          <div class="card-header">
            <div class="card-title">
              Error
            </div>
          </div>
          <div class="card-body text-center">
            <div style="border-radius:200px; height:200px; width:200px; background: #d8dfc8; margin:0 auto;">
              <i class="fa fa-info"></i>
            </div>
            <h1>Error</h1>
            <p>Your subscription plan failed, please go back and try again.</p>
            <p>Message us at: contact@smoothbalance.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StripeError",
};
</script>
<style scoped>

h1 {
  color: #D42F39;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

p {
  color: #404F5E;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}

i {
  color: #D42F39;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}

</style>