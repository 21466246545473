import store from '../store'

export default function authHeader() {

  let accessToken = store.getters['auth/getAccessToken'];

  if (accessToken) {
    return { Authorization: 'Bearer ' + accessToken };
  } else {
    return {};
  }
}