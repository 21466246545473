<template>
  <div>
    <div class="bg-pattern overlay-stReg logReg-container">
      <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-4 mx-auto">
        <!--login form block start-->
        <div class="card card-form sign-upIn _soleTrade">
          <div class="card-header">
            <div class="card-title">Sole trader</div>
          </div>
          <div class="card-body h-auto">
            <SoleTraderForm></SoleTraderForm>
          </div>
        </div>
        <!--ended login form block-->
      </div>
    </div>
  </div>
</template>
<script>
import SoleTraderForm from './SoleTraderForm.vue';
export default {
  name: "SoleTraderCompany",
  
  components:{
    SoleTraderForm
  },
  data() {
    return {
      loadingInstance: null,
      loading: false,
    };
  },
  mounted() {
    
  },
  computed: {
    pageTitle() {
      return (document.title = `${this.title.replace(/\b\w/g, (match) =>
          match.toUpperCase()
      )} | Smoothbalance`);
    },
  },
 methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: true
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
  },
};
</script>