<template>
  <!--login form block start-->
  <div class="card card-form _banking">


    <div class="card-header">
      <div class="card-title w-auto">
        Banking, IBAN and Payment Gateway
      </div>
    </div>
    <div class="card-body h-auto">
      <!--alert message block-->
      <!--Ended alert message block-->

      <form class="base-form h-100 d-flex justify-content-between flex-column">

        <div class="row m-0 mt-n6">
          <div class="w-100 mb-4 d-flex justify-content-end">
            <el-popover placement="top-start" :width="500" trigger="hover"
              content="The enabled section will be displayed in all outgoing Invoices and Quotations. Clients may choose to pay
                directly to the details provided. Please check with your bank and payment gateway before enabling the
                options. There might be transaction and other fees associated with banking and we hold no responsibility for
                transaction fees, loss of income or any other financial loss.">
              <template #reference>
                <span class="m-2 text-primary"><i class="fa fa-info-circle fa-lg"></i></span>
              </template>
            </el-popover>
          </div>
        </div>


        <div class="w-100">
          <div class="card-tabular">
            <div class="card-header">
              <div class="w-100 d-flex justify-content-between align-items-center flex-row">
                <div class="d-flex flex-grow-1">
                  Banking details <i class="fa fa-edit ms-3"></i>
                </div>
                <div class="d-flex flex-shrink-0">
                  <div class="form-check form-switch onOff">
                    <input class="form-check-input" type="checkbox" v-model="is_banking_info_available" id="hdr-chk-1">

                  </div>
                </div>
              </div>

            </div>
            <div class="card-body _wildSand pt-1">
              <form class="base-form w-100">
                <div class="tabular-row">
                  <input type="text" :disabled="!is_banking_info_available" v-model="accountant_name"
                    class="form-control transparent border-0" placeholder="Name of the account holder" />
                </div>
                <div class="tabular-row">
                  <input type="text" :disabled="!is_banking_info_available" v-model="branch_code"
                    class="form-control transparent border-0" placeholder="Sort code/Branch code/Routing number" />
                </div>
                <div class="tabular-row">
                  <input type="text" :disabled="!is_banking_info_available" v-model="account_number"
                    class="form-control transparent border-0" placeholder="Account number" />
                </div>
              </form>
            </div>
          </div>
          <div class="card-tabular mt-5">
            <div class="card-header">

              <div class="w-100 d-flex justify-content-between align-items-center flex-row">
                <div class="d-flex flex-grow-1">
                  International banking details
                </div>
                <div class="d-flex flex-shrink-0">
                  <div class="form-check form-switch onOff">
                    <input class="form-check-input" type="checkbox" id="hdr-chk-2"
                      v-model="is_international_banking_info_enable">

                  </div>
                </div>
              </div>

            </div>
            <div class="card-body _wildSand pt-1">
              <form class="base-form w-100">
                <div class="tabular-row">
                  <input type="text" v-model="swift_code" :disabled="!is_international_banking_info_enable"
                    class="form-control transparent border-0" placeholder="Enter Swift code or BIC code" />
                </div>
                <div class="tabular-row">
                  <input type="text" v-model="iban_number" :disabled="!is_international_banking_info_enable"
                    class="form-control transparent border-0"
                    placeholder="Enter IBAN number (International Banking Account Number)" />
                </div>

              </form>
            </div>
          </div>

          <div class="card-tabular mt-5">
            <div class="card-header flush-bg">

              <div class="w-100 d-flex justify-content-between align-items-center flex-row">
                <div class="d-flex flex-grow-1">
                  Pay<span class="text-activeLink me-2">Pal</span> details <span class="text-activeLink ms-3"><i
                      class="fa fa-edit"></i></span>
                </div>
                <div class="d-flex flex-shrink-0">
                  <div class="form-check form-switch onOff">
                    <input class="form-check-input" type="checkbox" id="hdr-chk-3" v-model="is_paypal_info_enable">
                  </div>
                </div>
              </div>

            </div>
            <div class="card-body _wildSand pt-1">
              <form class="base-form w-100">
                <div class="tabular-row">
                  <input type="text" v-model="paypal_id" :disabled="!is_paypal_info_enable"
                    class="form-control transparent border-0" placeholder="Enter PayPal ID or PayPal.me link" />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="w-100 mt-3 px-4">
          <div class="form-text">Check with your Bank, PayPal or Payment gateway for transaction fees</div>
        </div>

        <div class="form-footer mt-1">
          <div class="form-check outliner">
            <input type="checkbox" class="form-check-input" id="acceptTerms" v-model="paypalAccepted">
            <label class="form-check-label" for="acceptTerms">I accept payment using PayPal and methods mentioned
              above</label>
          </div>
        </div>
        <div class="form-footer mt-2 d-flex align-items-end">
          <button type="button" role="button" class="btn btn-success" @click="saveData">Save</button>
        </div>
      </form>

    </div>

  </div>
</template>
    
<script>

export default {
  name: "BankAndPayment",
  data() {
    return {
      loadingInstance: null,
      is_banking_info_available: false,
      accountant_name: "",
      branch_code: "",
      account_number: "",
      is_international_banking_info_enable: false,
      swift_code: "",
      iban_number: "",
      is_paypal_info_enable: false,
      paypal_id: "",
      paypalAccepted: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: true
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    saveData() {
      this.showLoading();

      let form_data = new FormData();

      form_data.append("is_banking_info_available", this.is_banking_info_available);
      form_data.append("accountant_name", this.accountant_name);
      form_data.append("branch_code", !this.branch_code);
      form_data.append("account_number", this.account_number);
      form_data.append("is_international_banking_info_enable", this.is_international_banking_info_enable);
      form_data.append("swift_code", !this.swift_code);
      form_data.append("iban_number", this.iban_number);
      form_data.append("is_paypal_info_enable", this.is_paypal_info_enable);
      form_data.append("paypal_id", this.paypal_id);

      this.$store
        .dispatch("setting/saveBankDetails", form_data)
        .then((res) => {
          // console.log(res);
          if (res.status == 1) {
            this.$toast.success("Save data Successful.");
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    getData() {
      this.showLoading();
      this.$store
        .dispatch("setting/getBankDetails")
        .then((res) => {
          if (res.data.status == 1) {
            if (res.data) {
              this.is_banking_info_available = res.data.is_banking_info_available;
              this.accountant_name = res.data.accountant_name;
              this.branch_code = res.data.branch_code;
              this.account_number = res.data.account_number;
              this.is_international_banking_info_enable = res.data.is_international_banking_info_enable;
              this.swift_code = res.data.swift_code;
              this.iban_number = res.data.iban_number;
              this.is_paypal_info_enable = res.data.is_paypal_info_enable;
              this.paypal_id = res.data.paypal_id;
            }
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>
  
<style scoped>
table thead tr th {
  background-color: #c4bb46;
  color: aliceblue;
}
</style>