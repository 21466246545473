<template>
    <div></div>
</template>
<script>
import store from '../../store'
  export default {
    name: 'Logout',
    beforeRouteEnter (to, from, next) {
      store.dispatch('auth/logout');
      next({name: "login"});
    }
  }
</script>