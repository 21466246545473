<template>
  <div>
    <section class="">
      <div class="bg-pattern overlay-soleCo logReg-container">
        <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-4 mx-auto">
          <!--login form block start-->
          <div class="card card-form">
            <div class="card-header">
              <div class="card-title">Select one</div>
            </div>
            <div class="card-body">
              <form
                class="base-form d-flex justify-content-between flex-column mb-4"
              >
                <h5 class="form-title">
                  Are you a sole trader or a limited company?
                </h5>
                <div
                  class="d-flex flex-column align-items-center justify-content-around mt-4"
                >
                  <router-link
                    class="btn btn-indigo btn-xl mb-4"
                    :to="{ name: `sole-trader` }"
                  >
                    Sole Trader
                  </router-link>
                  <router-link
                    class="btn btn-primary btn-xl"
                    :to="{ name: `limited-company` }"
                  >
                    Limited Company
                  </router-link>
                </div>
              </form>
            </div>
          </div>
          <!--ended login form block-->
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "SoleTraderLimitedCompany",
  
  computed: {
    pageTitle() {
      if (this.pageTitle) {
        return (document.title = `${this.title.replace(/\b\w/g, (match) =>
          match.toUpperCase()
        )} | Smoothbalance`);
      }
      return "Smoothbalance";
    },
  },
  mounted() {
    
  },
};
</script>