import { GET_EXPENSES, SAVE_EXPENSES, PAYMENT_METHOD, EXPENSE_CATEGORY, ALL_EXPENSE_CATEGORY, DELETE_EXPENSE, UPDATE_EXPENSES, DELETE_EXPENSE_ATTACHMENT, GENERATE_PDF, GET_EXPENSE_LIST_BY_ID, GENERATE_ZIP, GENERATE_EXCEL, CURRENCY_LIST_BY_USER, GET_EXPENSES_LIMIT } from '../config/url';
import axios from "axios";
import authHeader from './auth-header';

class ExpensesService {
    getList(filter) {
        return axios.post(GET_EXPENSES, filter, {
            headers: authHeader()   
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }


    saveExpense(expenses_data) {
        return axios.post(SAVE_EXPENSES, expenses_data, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }


    getExpensesLimit() {
        return axios.get(GET_EXPENSES_LIMIT, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    updateExpense(expenses_data) {
        return axios.post(UPDATE_EXPENSES, expenses_data, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }


    deleteExpensesAttachment(expenses_data) {
        return axios.post(DELETE_EXPENSE_ATTACHMENT, expenses_data, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    getPaymentOptions() {
        return axios.get(PAYMENT_METHOD, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    getAllExpenseCategory() {
        return axios.get(ALL_EXPENSE_CATEGORY, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    getExpenseCategory() {
        return axios.post(EXPENSE_CATEGORY, { content: 'category' }, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    deleteExpense(expenses_data) {
        const data = { expenseId: expenses_data };
        return axios.post(DELETE_EXPENSE, data, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    getExpenseListById(expenses_data) {
        // console.log(expenses_data)
        return axios.post(GET_EXPENSE_LIST_BY_ID, expenses_data, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    generatePdf(expenses_data) {
        const data = expenses_data;
        return axios.post(GENERATE_PDF, data, {
            headers: authHeader(),
            responseType: 'blob',//this is to get data as blob so that it can be displayed as pdf
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    generateZip(expenses_data) {
        const data = expenses_data;
        return axios.post(GENERATE_ZIP, data, {
            headers: authHeader(),
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    generateExcel(expenses_data) {
        const data = expenses_data;
        return axios.post(GENERATE_EXCEL, data, {
            headers: authHeader(),
            responseType: 'blob',
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    getUsersCurrency(expenses_data) {
        return axios.post(CURRENCY_LIST_BY_USER, {}, {
            headers: authHeader(),
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }
}

export default new ExpensesService();