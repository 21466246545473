<template>
  <div class="row">
    <div class="col-12">
      <div class="text-center mt-3">
        <el-badge
          :value="'i'"
          class="item"
          type="primary"
          @click="toggleInformation"
        >
          <el-card
            class="box-card text-center"
            style="border-color: #ebba03; border-width: 7px"
          >
            You can earn more free days or usage credit by using one of the
            options below.
          </el-card>
        </el-badge>
      </div>
    </div>
    <div class="col-6">
      <el-card class="box-card mt-3">
        <template #header>
          <div
            class="card-header"
            :style="{ backgroundColor: '#684e72', color: 'white' }"
          >
            <span>Enter voucher code</span>
          </div>
        </template>
        <div class="row">
          <div class="col-md-9">
            <el-input v-model="voucher" placeholder="Voucher code" />
          </div>
          <div class="col-md-3">
            <el-button type="warning">Send</el-button>
          </div>
        </div>
      </el-card>
    </div>
    <div class="col-6">
      <el-card class="box-card mt-3">
        <template #header>
          <div
            class="card-header"
            :style="{ backgroundColor: '#f1a6ab', color: 'white' }"
          >
            <span>Invite your friends</span>
          </div>
        </template>
        <div class="row">
          <div class="col-md-9">
            <el-input v-model="friend_email" placeholder="Email address" />
          </div>
          <div class="col-md-3">
            <el-button type="warning">Send</el-button>
          </div>
        </div>
      </el-card>
    </div>
    <div class="col-12">
      <el-card class="box-card mt-3" v-show="show_details">
        <h4><span>Earn more free usage!</span></h4>
        <p>
          <span
            >You can earn more free usage by entering voucher code, inviting
            your friends via email, sharing unique code or posting about us in
            your social media. Make use of it and earn free usage capped to 180
            days max.</span
          >
        </p>
        <h4>
          <span><br />Voucher code:</span>
        </h4>
        <p>
          <span
            >Successful Voucher code entry will give you extra days of free
            usage. This depends on the voucher code you enter. Keep an eye on
            your notification.</span
          >
        </p>
        <h4>
          <span><br />Email referral:</span>
        </h4>
        <p><span>Invite your friends to join Smooth Expense</span></p>
        <ul class="subscription-list ms-4">
          <li>
            <span>The 1st successful invite will give you 15 days</span>
          </li>
          <li>
            <span>The 2nd successful invite will give you 30 days</span>
          </li>
          <li>
            <span
              >The 3rd successful invite will give you 45 days of free usage.
            </span>
          </li>
        </ul>
      </el-card>
    </div>
  </div>
</template>

<script>

export default {
  name: "Voucher",
  data() {
    return {
      loadingInstance: null,
      voucher: "",
      friend_email: "",
      show_details: false,
    };
  },
 methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: true
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    toggleInformation() {
      this.show_details = !this.show_details;
    },
  },
};
</script>

<style scoped>
.subscription-list li {
  list-style: disc;
}
</style>