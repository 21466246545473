<template>
  <el-tabs v-model="activeName" class="settings">
    <el-tab-pane lazy label="General" class="row" name="general">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-5 mx-auto">
        <General></General>
      </div>
    </el-tab-pane>
    <el-tab-pane lazy label="Profile" class="row" name="profile">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-5 mx-auto">
        <div class="card card-form sign-upIn _soleTrade" v-if="isSoleTrader">
          <div class="card-header">
            <div class="card-title w-auto">
              Sole Trader
            </div>
          </div>
          <div class="card-body h-auto">
            <SoleTraderForm></SoleTraderForm>
          </div>
        </div>
        <div class="card card-form sign-upIn _soleTrade" v-else-if="isLimitedCompany">
          <div class="card-header">
            <div class="card-title w-auto">
              Limited Company
            </div>
          </div>
          <div class="card-body h-auto">
            <LimitedCompanyForm></LimitedCompanyForm>
          </div>
        </div>
        <div v-else class="clearfix">
          <span class="float-start">
            Please complete your profile by clicking
          </span>
          <a href="/complete-profile" class="float-start">&nbsp;here</a>.
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane lazy label="Control Panel" class="row" name="control_panel">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-5 mx-auto">
        <ControlPanel></ControlPanel>
      </div>
    </el-tab-pane>
    <el-tab-pane label="Tax and Registration" class="row" name="tax_and_registration">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-5 mx-auto">
        <TaxAndRegistration></TaxAndRegistration>
      </div>
    </el-tab-pane>
    <el-tab-pane lazy label="Banking and Paypal" class="row" name="banking_and_paypal">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-5 mx-auto">
        <BankAndPayment></BankAndPayment>
      </div>
    </el-tab-pane>
    <el-tab-pane lazy label="Subscription" name="subscription">
      <Subscription></Subscription>
    </el-tab-pane>
    <el-tab-pane label="Voucher, referral and share" name="voucher_referral_and_share">
      <Voucher></Voucher>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import SoleTraderForm from "../user/SoleTraderFormUpdate.vue";
import LimitedCompanyForm from "../user/LimitedCompanyFormUpdate.vue";
import General from "@/components/settings/General.vue";
import Subscription from "@/components/settings/Subscription.vue";
import ControlPanel from "@/components/settings/ControlPanel.vue";
import TaxAndRegistration from "@/components/settings/TaxAndRegistration.vue";
import BankAndPayment from "@/components/settings/BankAndPayment.vue";
import Voucher from "@/components/settings/Voucher.vue";

export default {
  name: "SettingComponent",

  components: {
    SoleTraderForm,
    LimitedCompanyForm,
    General,
    Subscription,
    Voucher,
    ControlPanel,
    TaxAndRegistration,
    BankAndPayment,
  },
  data() {
    return {
      loadingInstance: null,
      activeName: "general",
      isSoleTrader: false,
      isLimitedCompany: false,
    };
  },
  mounted() {
    // console.log(this.$store.getters["auth/getUserData"].companytype);
    this.isSoleTrader =
      this.$store.getters["auth/getUserData"].companytype == "Sole Trader"
        ? true
        : false;
    this.isLimitedCompany =
      this.$store.getters["auth/getUserData"].companytype == "Limited Companye"
        ? true
        : false;
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>