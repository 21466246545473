<template>
  <div>
    <section class="">
      <div class="bg-pattern overlay-region logReg-container">
        <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-4 mx-auto">
          <!--login form block start-->
          <div class="card card-form sign-upIn">
            <div class="card-header">
              <div class="card-title">
                Regional settings
              </div>
            </div>
            <div class="card-body">
              <form @submit.prevent="handleSubmit" class="base-form h-100 d-flex justify-content-between flex-column">
                <div class="cmn-fw">
                  <h5 class="form-title">Select from below</h5>

                  <div class="input-group _group-xl mb-3">
                    <span class="input-group-text prepend">
                      Country<span class="required">*</span>
                    </span>
                    <select v-model="form.country" class="form-select" required>
                      <option value="" selected disabled>Select</option>
                      <option v-for="option in country_options" :value="option.name" :key="option.id">{{ option.name }}</option>
                    </select>
                  </div>

                  <div class="input-group _group-xl mb-3">
                    <span class="input-group-text prepend">
                      Language<span class="required">*</span>
                    </span>
                    <select v-model="form.language" class="form-select" required>
                      <option selected="selected" disabled value="">Select</option>
                      <option value="en">English</option>
                      <option value="fr">French</option>
                      <option value="de">German</option>
                      <option value="it">Italian</option>
                      <option value="es">Spanish</option>
                    </select>
                  </div>
                  <div class="input-group _group-xl mb-3">
                    <span class="input-group-text prepend">
                      Currency<span class="required">*</span>
                    </span>
                    <select v-model="form.currency" class="form-select" required>
                      <option value="" selected disabled>Select</option>
                      <option v-for="option in currency_options" :value="option.id" :key="option.id">{{ option.name }}</option>
                    </select>
                  </div>
                </div>

                <div class="form-footer d-flex align-items-end">
                  <button type="submit" role="button" class="btn btn-success">Next</button>
                </div>
              </form>
            </div>

          </div>
          <!--ended login form block-->
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import {COUNTRIES_LIST, CURRENCY_LIST} from "../../config/url";

export default {
  name: 'Regional',
  
  data() {
    return {
      loadingInstance: null,
      countries: null,
      currencies: null,
      form: {
        fullname: "",
        email: "",
        password: "",
        country: "",
        currency: "",
        version: "",
        language: "en"
      }
    }
  },
  mounted() {
    this.fetchCountries();
    this.fetchCurrencies();
    
  },
  computed: {
    pageTitle() {
      return document.title = `${this.title.replace(/\b\w/g, (match) =>
          match.toUpperCase()
      )} | Smoothbalance`;
    },
    country_options() {
      if (this.countries) {
        return this.countries.map((v) => {
          return {
            'id': v.id,
            'name': v.name
          };
        });
      }
      return [];
    },
    currency_options() {
      if (this.currencies) {
        return this.currencies.map((v) => {
          return {
            'id': v.id,
            'name': v.name
          };
        });
      }
      return [];
    }
  },
 methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: true
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    async fetchCountries() {
      this.countries = await this.axios.get(COUNTRIES_LIST).then(function (res) {
        return res.data.response;
      }).catch(err => {
        console.log(err);
      });
    },
    async fetchCurrencies() {
      this.currencies = await this.axios.get(CURRENCY_LIST).then(function (res) {
        return res.data.response;
      }).catch(err => {
        console.log(err);
      });
    },
    handleSubmit() {
      // save data to state.
      this.$store.dispatch('register/saveRegisterStep', this.form);
      // redirect to user signup page.
      this.$router.push("register");
    }
  }
};
</script>
<style lang="scss">
// @import '~mdb-ui-kit/css/mdb.min.css';
// #app {
//   font-family: Roboto, Helvetica, Arial, sans-serif;
// }
</style>