<template>
  <div class="card card-form _control_panel">
    <div class="card-header">
      <div class="card-title">
        Control Panel
      </div>
    </div>
    <div class="card-body">
      <div class="base-form h-100 justify-content-between flex-column">
        <div class="w-100 pt-4">
          <div class="card-tabular">
            <div class="card-header">
              Invoice Terms (days)
            </div>
            <div class="card-body _wildSand pt-1">
              <input type="number" name="txNum" class="form-control transparent border-0"
                placeholder="company tax/vat number" v-model="invoice_term" min="1" max="31">
            </div>
          </div>

        </div>
        <div class="w-100 pt-4">
          <div class="card-tabular">
            <div class="card-header">
              Quotation Terms (days)
            </div>
            <div class="card-body _wildSand pt-1">

              <input type="number" name="txNum" class="form-control transparent border-0"
                placeholder="company tax/vat number" v-model="quotation_term" min="1" max="31">

            </div>
          </div>

        </div>
      </div>
      <div class="form-footer d-flex align-items-end">
        <button type="submit" role="button" class="btn btn-success float-end mb-4" @click="saveData">
          Update
        </button>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "ControlPanel",
  data() {
    return {
      loadingInstance: null,
      invoice_term: '',
      quotation_term: '',
    };
  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: true
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    saveData() { },
  },
};
</script>

<style scoped></style>