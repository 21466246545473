<template>
  <div>
    <section>
      <div class="bg-pattern overlay-ltdcReg logReg-container">
        <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-4 mx-auto">
          <!--login form block start-->
          <div class="card card-form sign-upIn _company">
            <div class="card-header">
              <div class="card-title">Limited company</div>
            </div>
            <div class="card-body h-auto">
              <LimitedCompanyForm></LimitedCompanyForm>
            </div>
          </div>
          <!--ended login form block-->
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import LimitedCompanyForm from './LimitedCompanyForm.vue';

export default {
  name: "LimitedCompany",
  
  components: {
    LimitedCompanyForm
  },
  data() {
    return {
      loadingInstance: null,
      loading: false,
    };
  },
  created() {
    
  },
  computed: {
    
  },
  methods: {
  },
};
</script>