<template>
  <div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <el-card class="box-card mb-3">
          <template #header>
            <div class="card-header" :style="{ backgroundColor: '#684e72', color: 'white' }">
              <span>Account ID</span>
            </div>
          </template>
          <div>{{ user_details.email }}</div>
        </el-card>
      </div>
      <div class="col-md-6 col-sm-12">
        <el-card class="box-card mb-3">
          <template #header>
            <div class="card-header" :style="{ backgroundColor: '#684e72', color: 'white' }">
              <span>Subscription Plan</span>
            </div>
          </template>
          <div>
            {{ noOfDaysRemaining == "N/A" ? "Free" : noOfDaysRemaining }}
          </div>
        </el-card>
      </div>
      <div class="col-md-6 col-sm-12">
        <el-card class="box-card mb-3 pb-2">
          <template #header>
            <div class="card-header" :style="{ backgroundColor: '#eabb02', color: 'white' }">
              <span>Free plan includes</span>
            </div>
          </template>
          <div>
            <ul class="subscription-list ms-4">
              <li v-for="(free_sub, key) in Object.values(free_subscription)" :key="'sub_free_1' + key">
                {{ free_sub }}
              </li>
            </ul>
          </div>
        </el-card>
      </div>
      <div class="col-md-6 col-sm-12">
        <el-card class="box-card mb-3">
          <template #header>
            <div class="card-header" :style="{ backgroundColor: '#684e72', color: 'white' }">
              <span>Free credit usage log</span>
            </div>
          </template>
          <div class="row">
            <span class="col-md-10 mb-2">Total credit per month</span>
            <span class="col-md-2 mb-2">
              <el-input v-model="user_details.total_credit_per_month" disabled/>
            </span>
            <div class="clearfix"></div>
            <span class="col-md-10">Used credit</span>
            <span class="col-md-2 text-center mb-2">
              <el-input v-model="user_details.used_credit" disabled/>
            </span>
          </div>
          <div class="row bg-warning">
            <span class="col-md-10 pt-2 mb-2">Credit remaining</span>
            <span class="col-md-2 text-center pt-2 mb-2">
              {{ user_details.remaining_credit }}
            </span>
          </div>
        </el-card>
      </div>
      <div class="col-md-6 col-sm-12">
        <el-card class="box-card mb-3">
          <template #header>
            <div class="card-header text-center" :style="{ backgroundColor: '#eabb02', color: 'white' }">
              <span>Upgrade to pro includes</span>
            </div>
          </template>
          <div>
            <ul class="subscription-list ms-4">
              <li v-for="(pro_sub, key) in Object.values(pro_subscription)" :key="'sub_free' + key">
                {{ pro_sub }}
              </li>
            </ul>
          </div>
        </el-card>
      </div>
      <div class="col-md-6 col-sm-12">
        <el-card class="box-card mb-3">
          <template #header>
            <div class="card-header bg-secondary" :style="{ color: 'white' }">
              <span>Upgrade to pro now</span>
            </div>
          </template>
          <div class="text-center">
            <div class="row">
              <div class="col-md-6 col-sm-12" v-for="(list_sub, key_sub) in subscription_plan_list"
                   :key="'sub_' + key_sub">
                <h6>{{ list_sub.packageName }}</h6>
                <el-button type="primary" @click="getSubscriptionCheckout(list_sub.packageId, list_sub.packagePrice)">{{
                    list_sub.packagePrice_code
                  }}
                </el-button>
              </div>
            </div>
          </div>
        </el-card>
      </div>


      <!-- <div class="col-md-6 col-sm-12">
        <el-card class="box-card mb-3">
          <template #header>
            <div class="card-header bg-secondary" :style="{ color: 'white' }">
              <span>Upgrade to pro now</span>
            </div>
          </template>
          <div class="text-center">
            <PayPalButtonMonthly :plan-id-monthly="paypalMonthlyPlanId" :plan-id-yearly="paypalMonthlyPlanId">
            </PayPalButtonMonthly>
          </div>
        </el-card>
      </div> -->
    </div>
  </div>
</template>

<script>
// import {loadStripe} from '@stripe/stripe-js';
// import PayPalButtonMonthly from "./paypal-monthly-button.vue";

export default {
  name: "Subscription",
  // components: {
  //   PayPalButtonMonthly
  // },
  data() {
    return {
      user_details: {
        email: "",
        subscription_plan: "",
        total_credit_per_month: 5,
        used_credit: 0,
        remaining_credit: 0,
      },
      free_subscription: [],
      pro_subscription: [],
      month: "0",
      monthprice: 0.0,
      year: "0",
      yearprice: 0.0,
      noOfDaysRemaining: "",
      stripe: null,
      paypal_color: '',
      subscription_plan_list: {},
      // paypalMonthlyPlanId: process.env.VUE_APP_PAYPAL_ITEMS_MONTHLY,
      // paypalYearlyPlanId: process.env.VUE_APP_PAYPAL_ITEMS_YEARLY,
      // stripe_key: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      // monthly_price_api: process.env.VUE_APP_LINE_ITEMS_MONTHLY,
      // yearly_price_api: process.env.VUE_APP_LINE_ITEMS_YEARLY,
      successURL: window.location.origin + "/stripe-success",
      cancelURL: window.location.origin + "/stripe-error",
    };
  },
  async mounted() {
    // await this.getStripeData();
    this.user_details.email = this.$store.getters["auth/getUserData"].email;
    this.user_details.subscription_plan =
        this.$store.getters["auth/getUserData"].subscription_details
            .subscription_type === "N/A"
            ? "Free"
            : this.$store.getters["auth/getUserData"].subscription_details
                .subscription_type;

    await this.getData();
    await this.getSubscriptionList();
  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: true
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    async getData() {
      this.showLoading();
      const data = {
        app_type: "SmoothBalance",
      };

      await this.$store
          .dispatch("setting/getList", data)
          .then((res) => {
            if (res.data.status == 1) {
              this.free_subscription = res.data.response.free_subscription[0];
              this.pro_subscription = res.data.response.pro[0];

              this.month = res.data.response.upgrade[0].month;
              this.monthprice = res.data.response.upgrade[0].monthprice;
              this.year = res.data.response.upgrade[0].year;
              this.yearprice = res.data.response.upgrade[0].yearprice;
              this.noOfDaysRemaining =
                  res.data.response.subscription[0].noOfDaysRemaining == "expired"
                      ? "Expired"
                      : res.data.response.subscription[0].noOfDaysRemaining;
            } else {
              throw new Error(res);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error("Something went wrong. Please try again.");
          })
          .finally(() => {
            this.hideLoading();
          });
    },
    async getSubscriptionList() {
      const data = {
        app_type: "SmoothBalance",
      };

      this.$store
          .dispatch("setting/getSubscriptionList", data)
          .then((res) => {
            if (res.data.status == 1) {
              this.subscription_plan_list = res.data.response.packages;
            } else {
              throw new Error(res);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error("Something went wrong. Please try again.");
          })
          .finally(() => {
            this.hideLoading();
          });
    },
    getSubscriptionCheckout(packageId, packagePrice) {
      const data = {
        app_type: "SmoothBalance",
        packageId: packageId,
        packagePrice: packagePrice,
        gateway: 'stripe',
        successUrl: this.successURL,
        cancelUrl: this.cancelURL,
      };
      this.$store
          .dispatch("setting/getSubscriptionCheckout", data)
          .then((res) => {
            if (res.data.status == 1) {
              localStorage.setItem('paymentId', res.data.response.paymentId);
              window.open(res.data.response.pgUrl, '_blank');
            } else {
              throw new Error(res);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error("Something went wrong. Please try again.");
          })
          .finally(() => {
            this.hideLoading();
          });
    }
  },
};
</script>

<style scoped>
.subscription-list li {
  list-style: disc;
}
</style>