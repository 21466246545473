const initialState = {
    fullname: "",
    email: "",
    password: "",
    country: "",
    currency: "",
    version: "web",
    language: ""
}

export const register = {
  namespaced: true,
  state: initialState,
  actions: {
    saveRegisterStep({ commit }, user) {
      commit('save', user);
    }
  },
  mutations: {
    save(state, user) {
      state = Object.assign(state, user);
    }
  }
};