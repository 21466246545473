import { UPDATE_PROFILE } from '../config/url';
import axios from "axios";
import authHeader from './auth-header';

class CompanyService {
  register(company) {
    // console.log(company)
    return axios.post(UPDATE_PROFILE, company, {
      headers: authHeader()
    }).then((res) => {
      return res;
    }).catch(error => {
      throw new Error(error)
    });
  }
}

export default new CompanyService();