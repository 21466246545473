import {GET_SUBSCRIPTION, GET_TAX_DETAILS, GET_BANK_DETAILS, UPDATE_TAX, UPDATE_BANK_DETAILS, GET_SUBSCRIPTION_LIST, GET_SUBSCRIPTION_CHECKOUT,GET_SUBSCRIPTION_SUCCESS_HANDLER} from '@/config/url';
import axios from "axios";
import authHeader from './auth-header';

class SettingService {
    getList(SmoothBalance) {
        return axios.post(GET_SUBSCRIPTION, SmoothBalance, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    getSubscriptionList(filter) {
        return axios.post(GET_SUBSCRIPTION_LIST, filter, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    getSubscriptionCheckout(filter) {
        return axios.post(GET_SUBSCRIPTION_CHECKOUT, filter, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    successHandler(filter) {
        return axios.post(GET_SUBSCRIPTION_SUCCESS_HANDLER, filter, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    getTaxDetails() {
        return axios.get(GET_TAX_DETAILS, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    saveTax(tax_details) {
        return axios.post(UPDATE_TAX, tax_details, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    getBankDetails() {
        return axios.get(GET_BANK_DETAILS, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    saveBankDetails(bank_details) {
        return axios.post(UPDATE_BANK_DETAILS, bank_details, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }
}

export default new SettingService();